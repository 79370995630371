import { CreateUserPage } from "./pages/CreateUser.tsx";
import { FC, lazy } from "react";
import { LoadingSpinner } from "./UI/Loading/LoadingSpinner.tsx";
import { PricebookPage } from "./pages/settings/PricebookPage.tsx";
import { Route, Routes } from "react-router-dom";
import { useAuthContext } from "./components/Auth/AuthWrapper.tsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import { UserDetailsPage } from "./pages/UserDetails.tsx";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AccountFormDetail from "./pages/settings/forms/AccountFormDetail.tsx";
import AccountFormsPage from "./pages/settings/forms/AccountFormsPage.tsx";
import AccountSettingsPage from "./pages/settings/AccountSettingsPage.tsx";
import AssetHistoryPage from "./pages/settings/AssetHistory.tsx";
import BranchSettingsPage from "./pages/settings/BranchSettingsPage.tsx";
import CreateBranchPage from "./pages/settings/CreateBranch.tsx";
import CreateDivisionPage from "./pages/settings/CreateDivision.tsx";
import CreateQuotePage from "./pages/CreateQuotePage.tsx";
import DivisionSettingsPage from "./pages/settings/DivisionSettingsPage.tsx";
import EditRawFormPage from "./pages/settings/forms/EditRawFormPage.tsx";
import FlaggedUsersPage from "./pages/Users.tsx";
import JobDetailPage from "./pages/JobDetail.tsx";
import JobDocuments from "./pages/JobDocuments.tsx";
import JobPage from "./pages/JobPage.tsx";
import LoginPage from "./pages/Login";
import Page404 from "./pages/404";
import PricebookItemPage from "./pages/settings/PricebookItemPage.tsx";
import ProfilePage from "./pages/ProfilePage.tsx";
import QuoteDetailsPage from "./pages/QuoteDetailsPage.tsx";
import QuotingPage from "./pages/QuotingPage.tsx";
import SettingsAddUserPage from "./pages/SettingsAddUserPage.tsx";
import SettingsCreateFormPage from "./pages/settings/forms/CreateFormPage.tsx";
import SettingsUserDetailsPage from "./pages/SettingsUserDetailsPage.tsx";
import UsersPage from "./pages/Users.tsx";
import AssetOverviewPage from "./pages/AssetOverviewPage.tsx";
import { AssetDocuments } from "./pages/AssetDocuments.tsx";
import { AssetImagesPage } from "./pages/AssetImagesPage.tsx";

const AssetDetailPage = lazy(() => import("./pages/AssetDetail"));
const AssetsBrowserPage = lazy(() => import("./pages/AssetsBrowser.tsx"));
const AssetsSearchPage = lazy(() => import("./pages/AssetsSearch.tsx"));
const AssetsSettingsPage = lazy(
  () => import("./pages/settings/AssetsSettings.tsx")
);
const CreateAssetPage = lazy(() => import("./pages/CreateAsset"));
const DashboardPage = lazy(() => import("./pages/Dashboard.tsx"));
const DocumentDetailsPage = lazy(
  () => import("./pages/DocumentDetailsPage.tsx")
);
const DocumentsPage = lazy(() => import("./pages/DocumentsPage.tsx"));
const EditCSVConfig = lazy(
  () => import("./pages/settings/forms/EditCSVConfig.tsx")
);
const EditDynamicDocument = lazy(
  () => import("./pages/settings/forms/EditDynamicDocument.tsx")
);
const EditMobileLayout = lazy(
  () => import("./pages/settings/forms/EditMobileLayout.tsx")
);
const EditPDFConfig = lazy(
  () => import("./pages/settings/forms/EditPDFConfig.tsx")
);
const EditWebLayout = lazy(
  () => import("./pages/settings/forms/EditWebLayout.tsx")
);
const IconsPage = lazy(() => import("./pages/IconsPage.tsx"));
const MapPage = lazy(() => import("./pages/MapPage.tsx"));
const SchedulePage = lazy(() => import("./pages/Schedule.tsx"));
const CreateFormSubmissionPage = lazy(
  () => import("./pages/CreateFormSubmissionPage.tsx")
);

export const AppRouter: FC = withAuthenticationRequired(
  () => {
    const {
      hasTimecardAdmin,
      hasRibbiotAdmin,
      hasWebAssetMap,
      hasAssetCrud,
      hasUserCrud,
      hasWebSchedule,
      hasWebQuoting,
    } = useAuthContext();

    const { jobDocumentsEnabled } = useFlags();

    return (
      <>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          {(hasWebAssetMap || hasRibbiotAdmin) && (
            <>
              <Route path="/map" element={<MapPage />} />
              <Route path="/assets/browser" element={<AssetsBrowserPage />} />
              <Route path="/assets/search" element={<AssetsSearchPage />} />
              <Route path="/assets/overview" element={<AssetOverviewPage />} />
              <Route
                path="/assets/asset-images"
                element={<AssetImagesPage />}
              />
              <Route
                path="/assets/asset-documents"
                element={<AssetDocuments />}
              />
            </>
          )}
          {(hasWebSchedule || hasRibbiotAdmin) && (
            <>
              <Route path="/jobs/create" element={<JobPage />} />
              <Route path="/jobs/dashboard" element={<DashboardPage />} />
              <Route path="/jobs/job-detail" element={<JobDetailPage />} />
              {jobDocumentsEnabled && (
                <Route path="/jobs/job-documents" element={<JobDocuments />} />
              )}
              <Route path="/schedule" element={<SchedulePage />} />
            </>
          )}
          {(hasAssetCrud || hasRibbiotAdmin) && (
            <>
              <Route path="/assets/browser" element={<AssetsBrowserPage />} />
              <Route path="/assets/search" element={<AssetsSearchPage />} />
              <Route path="/assets/overview" element={<AssetOverviewPage />} />
              <Route
                path="/assets/asset-detail"
                element={<AssetDetailPage />}
              />
              <Route
                path="/assets/asset-images"
                element={<AssetImagesPage />}
              />
              <Route
                path="/assets/asset-documents"
                element={<AssetDocuments />}
              />
              <Route path="/assets/create" element={<CreateAssetPage />} />
            </>
          )}

          {(hasUserCrud || hasRibbiotAdmin) && (
            <>
              <Route path="/users" element={<FlaggedUsersPage />} />
              <Route path="/users/user-detail" element={<UserDetailsPage />} />
              <Route path="/users/create" element={<CreateUserPage />} />
            </>
          )}

          {(hasRibbiotAdmin || hasTimecardAdmin) && (
            <>
              <Route path="/documents" element={<DocumentsPage />} />
              <Route
                path="/documents/create"
                element={<CreateFormSubmissionPage />}
              />
              <Route
                path="/documents/document-detail"
                element={<DocumentDetailsPage />}
              />
            </>
          )}
          {(hasRibbiotAdmin || hasWebQuoting) && (
            <>
              <Route path="/quoting" element={<QuotingPage />} />
              <Route
                path="/quoting/quote-detail"
                element={<QuoteDetailsPage />}
              />
              <Route path="/quoting/create" element={<CreateQuotePage />} />
            </>
          )}
          {hasRibbiotAdmin && (
            <>
              <Route path="/settings" element={<AccountSettingsPage />} />
              <Route path="/settings/forms" element={<AccountFormsPage />} />
              <Route
                path="/settings/forms/:formid"
                element={<AccountFormDetail />}
              />
              <Route
                path="/settings/forms/create"
                element={<SettingsCreateFormPage />}
              />
              <Route
                path="/settings/forms/:formid/edit-raw-form"
                element={<EditRawFormPage />}
              />
              <Route
                path="/settings/forms/:formid/edit-dynamic-document"
                element={<EditDynamicDocument />}
              />
              <Route
                path="/settings/forms/:formid/edit-web-layout"
                element={<EditWebLayout />}
              />
              <Route
                path="/settings/forms/:formid/edit-ios-layout"
                element={<EditMobileLayout type="IOS" />}
              />
              <Route
                path="/settings/forms/:formid/edit-android-layout"
                element={<EditMobileLayout type="Android" />}
              />
              <Route
                path="/settings/forms/:formid/edit-csv-config"
                element={<EditCSVConfig />}
              />
              <Route
                path="/settings/branch/detail"
                element={<BranchSettingsPage />}
              />
              <Route
                path="/settings/division/detail"
                element={<DivisionSettingsPage />}
              />
              <Route
                path="/settings/division/create"
                element={<CreateDivisionPage />}
              />
              <Route
                path="/settings/branch/create"
                element={<CreateBranchPage />}
              />
              <Route
                path="/settings/location"
                element={<AssetsSettingsPage />}
              />
              <Route
                path="/settings/location/:assetid"
                element={<AssetHistoryPage />}
              />
              <Route path="/settings/icons" element={<IconsPage />} />
              <Route path="/settings/users" element={<UsersPage settings />} />
              <Route
                path="/settings/users/create"
                element={<SettingsAddUserPage />}
              />
              <Route
                path="/settings/users/user-detail"
                element={<SettingsUserDetailsPage />}
              />
              <Route path="/settings/pricebook" element={<PricebookPage />} />
              <Route
                path="/settings/pricebook/create"
                element={<PricebookItemPage />}
              />
              <Route
                path="/settings/forms/:formid/edit-pdf-config"
                element={<EditPDFConfig />}
              />
            </>
          )}
          <Route
            path="*"
            element={
              hasTimecardAdmin === undefined ||
              hasRibbiotAdmin === undefined ? (
                <LoadingSpinner />
              ) : (
                <Page404 />
              )
            }
          />
        </Routes>
      </>
    );
  },
  {
    onRedirecting: () => <LoadingSpinner />,
  }
);
