/* eslint-disable @typescript-eslint/no-explicit-any */
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Button } from "@/UI/Button";
import { Divider } from "@/UI/Divider";
import { downloadFile } from "@/utils/helpers/downloadFile";
import { EditFormRenderer } from "@/components/Forms/FormEngine/EditFormRenderer";
import { FC, useEffect, useRef, useState } from "react";
import { FormRenderer } from "@/components/Forms/FormEngine/FormRenderer";
import { LoadingSpinner } from "@/UI/Loading";
import { PDFIcon, PagesIcon } from "@/assets";
import { Spinner } from "@/UI/Spinner";
import { StatusSelector } from "@/UI/StatusSelector/StatusSelector";
import { StickyHeader } from "@/UI/StickyHeader";
import { toast } from "react-toastify";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useExportDocumentPDF } from "@/lib/react-query/mutationHooks/useExportDocumentPDF";
import { useFormRendererStore } from "@/lib/zustand/formRendererStore";
import { useFormSubmission } from "@/lib/react-query/queryHooks/useFormSubmission";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import {
  EditFormSubmissionBody,
  useEditFormSubmission,
} from "@/lib/react-query/mutationHooks/useEditFormSubmission";
import {
  FormSubmission,
  FormSubmissionHistoryAuditRecord,
} from "@/types/forms/general";
import * as amplitude from "@amplitude/analytics-browser";
import { ConvertIcon } from "@/assets/ConvertIcon";
import { getFormSubmissionValueById } from "@/utils/helpers/forms/formRendererHelpers";
import { formatDateOnlyTimezone } from "@/utils/helpers/dateFormat";
import { EditHistory } from "@/components/Forms/FormEngine/EditHistory";
import { useEditFormSubmissionHistory } from "@/lib/react-query/queryHooks/useEditFormSubmissionHistory";
import { queryClient } from "@/lib/react-query/general";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { PDF_LOADING_TIME } from "@/utils/hooks/useDocumentDetailHelpers";
import { useFormTemplate } from "@/lib/react-query/queryHooks/useFormTemplate";
import { useFlags } from "launchdarkly-react-client-sdk";
import { parseLocationValue } from "@/utils/helpers/forms/parseLocationValue";
import { format } from "date-fns";

const QuoteDetailsPage: FC = () => {
  const { webGqlFormTemplatesMigration } = useFlags();
  const location = useLocation();
  const navigate = useNavigate();
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();
  const { ribbiotId, accountId } = useAuthContext();
  const searchParams = new URLSearchParams(location.search);
  const formId = searchParams.get("formId");
  const formSubmissionId = searchParams.get("formSubmissionId");

  const { data: accountData, isLoading: accountLoading } = useAccount({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: quoteFormTemplate, isLoading: quoteFormTemplateLoading } =
    useFormTemplate({
      formTemplateId: formId ?? "",
      options: { staleTime: 5 * 1000 * 60, enabled: !!formId },
    });

  const getFormTemplate = () => {
    if (
      webGqlFormTemplatesMigration === "dualwrite" ||
      webGqlFormTemplatesMigration === "live"
    ) {
      return quoteFormTemplate?.data?.formTemplate;
    }
    return accountData?.data.forms.find((form) => form.id === formId);
  };

  const [isValidFormSubmit, setIsValidFormSubmit] = useState(false);
  const [PDFReloading, setPDFReloading] = useState(false);
  const [formViewedEventFired, setFormViewedEventFired] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const submitRef = useRef<HTMLButtonElement>(null);

  const exportDocumentPDF = useExportDocumentPDF();

  const formSubmissionQuery = useFormSubmission({
    formId,
    formSubmissionId,
    options: {
      enabled: !!formSubmissionId && !!formId,
    },
  });

  const {
    data: auditData,
    refetch: refetchAuditHistory,
    isLoading: isEditFormSubmissionHistoryLoading,
    isError: editFormSubmissionHistoryError,
  } = useEditFormSubmissionHistory({
    formSubmissionId,
  });

  const {
    formObject,
    setFormObject,
    formSubmission,
    setFormSubmission,
    clear,
  } = useFormRendererStore((state) => ({
    formObject: state.formObject,
    setFormObject: state.setFormObject,
    formSubmission: state.formSubmission,
    setFormSubmission: state.setFormSubmission,
    clear: state.clear,
  }));

  const {
    mutateAsync: editFormSubmission,
    isPending: editFormSubmissionLoading,
    variables: editFormSubmissionVariables,
  } = useEditFormSubmission();

  const handleDocumentPDF = async (formSubmissionId: string) => {
    await exportDocumentPDF.mutateAsync({ formSubmissionId }).then((doc) =>
      downloadFile({
        extension: "pdf",
        file: doc.data,
        fileName: "PDF",
      })
    );
    amplitude.track(AMPLITUDE_EVENTS.FORM_PDF_DOWNLOADED, {
      page_name: "quoting_details",
      form_name: formObject?.name ?? "N/A",
    });
  };

  const handleEditFormSubmissionFields = async (params: {
    editFormSubmissionBody: EditFormSubmissionBody;
    changeNotes?: string;
    localDateTime?: string;
  }) => {
    const { changeNotes, editFormSubmissionBody, localDateTime } = params;
    if (!formSubmission?.id || !formSubmission?.formId) return;
    const oldFormSubmission = structuredClone(formSubmission);
    let newFormSubmission: FormSubmission | undefined = undefined;
    setIsEdit(false);
    const body = {
      fields: editFormSubmissionBody.fields,
      localDateTime,
      changeNotes,
    };
    try {
      const res = await editFormSubmission({
        submissionId: formSubmission.id,
        formId: formSubmission.formId,
        body,
      });

      if (res.data) {
        toast.success("Form Submission Updated.");
        newFormSubmission = {
          ...formSubmission,
          localDateTime: localDateTime ?? formSubmission.localDateTime,
          fields: [
            ...formSubmission.fields,
            ...(editFormSubmissionBody.fields ?? []),
          ],
        } as FormSubmission;
        setFormSubmission(newFormSubmission);

        amplitude.track(AMPLITUDE_EVENTS.QUOTE_EDITED, {
          quote_date: localDateTime || "",
          quote_number: formSubmission.data.number || "",
        });
      }
      formSubmissionQuery.refetch();
      setPDFReloading(true);
      setTimeout(() => {
        // Set PDF in loading state to give time for server to update the PDF after form submission edit
        setPDFReloading(false);

        // Sync audit history with backend
        refetchAuditHistory();
      }, PDF_LOADING_TIME);

      // Optimistically update audit history
      queryClient.setQueryData<{
        data: FormSubmissionHistoryAuditRecord[];
      }>(
        [QUERY_KEYS.EDIT_FORM_SUBMISSION_HISTORY, { formSubmissionId }],
        (previousHistory: any) => {
          const newAuditRecord = {
            accountId,
            changeNotes,
            createdAt: new Date().toISOString(),
            formSubmissionId,
            hidden: false,
            id: new Date().getTime().toString(),
            oldRecord: oldFormSubmission,
            newRecord: newFormSubmission,
            diffRecord: { fields: editFormSubmissionBody.fields },
            userId: ribbiotId,
          };
          return { data: [...previousHistory.data, newAuditRecord] };
        }
      );
    } catch (error) {
      toast.error("Unable to update Form Submission.");
    }
  };

  const handleEditFormSubmissionStatus = async (status: string) => {
    if (!formSubmission?.id || !formSubmission?.formId) return;
    const res = await editFormSubmission({
      submissionId: formSubmission.id,
      formId: formSubmission.formId,
      body: {
        data: {
          status,
        },
      },
    });

    if (res.data) {
      toast.success("Status updated.");
      const newFormSubmission = {
        ...formSubmission,
        data: { ...formSubmission.data, status },
      };
      setFormSubmission(newFormSubmission);
      amplitude.track(AMPLITUDE_EVENTS.FORM_STATUS_CHANGED, {
        status_name: newFormSubmission.data.status,
        form_number: newFormSubmission.data.number,
        form_name: formObject?.name,
        page_name: "quote_details",
      });
    } else {
      toast.error("Unable to update status.");
    }
  };

  const onSave = () => {
    if (!submitRef.current) return;
    submitRef.current.click();
  };

  useEffect(() => {
    if (
      accountLoading ||
      quoteFormTemplateLoading ||
      formSubmissionQuery.isLoading
    ) {
      return;
    }
    const flaggedFormTemplate = getFormTemplate();
    if (!flaggedFormTemplate || !formSubmissionQuery?.data?.data) {
      setNotFound({ notFound: true, message: "No Form Found" });
      return;
    }
    setFormObject(flaggedFormTemplate);
    setFormSubmission(formSubmissionQuery.data?.data);
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountData,
    accountLoading,
    quoteFormTemplate,
    formId,
    formSubmissionQuery?.data,
    formSubmissionQuery?.isLoading,
  ]);

  useEffect(() => {
    if (!formObject || !formSubmission || formViewedEventFired) return;
    amplitude.track(AMPLITUDE_EVENTS.FORM_VIEWED, {
      form_number: formSubmission.data.number,
      status: formSubmission.data.status,
    });
    setFormViewedEventFired(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObject, formSubmission]);

  useEffect(() => {
    if (!formId || !formSubmissionId) navigate("/quoting");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, formSubmissionId]);

  const convertToJob = () => {
    const stateMapping: Partial<Record<string, string>> = {};
    formObject?.layouts?.web?.fields?.map((field) => {
      if (!field?.fields) return;
      field.fields.map((subField) => {
        const mapping = subField?.attributes?.mapping;
        if (!subField.id || !mapping) return;
        stateMapping[mapping] = getFormSubmissionValueById(subField.id);
      });
      return;
    });

    amplitude.track(AMPLITUDE_EVENTS.QUOTE_CONVERTED, {
      quote_date: format(new Date(formSubmission?.createdAt || ""), "P"),
      quote_number: formSubmission?.data.number || "",
    });

    navigate("/jobs/create", {
      state: {
        mapping: {
          ...stateMapping,
          number: formSubmission?.data?.number
            ?.split("-")
            .toSpliced(0, 1)
            .join("-"),
          startDate: stateMapping?.startDate
            ? formatDateOnlyTimezone(
                new Date(stateMapping.startDate),
                formSubmission?.timezoneOffset ?? ""
              )
            : undefined,
          endDate: stateMapping?.endDate
            ? formatDateOnlyTimezone(
                new Date(stateMapping.endDate),
                formSubmission?.timezoneOffset ?? ""
              )
            : undefined,
          location: parseLocationValue(stateMapping.location || ""),
        },
      },
    });
  };

  const duplicateQuote = () => {
    const defaultValues = formSubmission?.fields?.reduce(
      (acc: Record<string, any>, curr) => {
        acc[curr.id] = curr.value;
        return acc;
      },
      {}
    );

    amplitude.track(AMPLITUDE_EVENTS.QUOTE_COPIED, {
      quote_date: format(new Date(formSubmission?.createdAt || ""), "P"),
      quote_number: formSubmission?.data.number || "",
    });

    navigate(`/quoting/create?formId=${formId}`, { state: defaultValues });
  };

  useEffect(() => {
    if (editFormSubmissionHistoryError) {
      toast.error("Error Fetching Audit History");
    }
  }, [editFormSubmissionHistoryError]);

  useEffect(() => {
    if (
      !location?.state?.new ||
      !formObject ||
      !formSubmission ||
      PDFReloading ||
      !!formSubmission?.hasFile
    ) {
      return;
    }
    // Clear the navigate state
    navigate(`${location.pathname}${location.search}`, { state: {} });
    // On a new quote, set the inital pdf loading state to true and reload
    setPDFReloading(true);
    const pdfLoadingTimeout = setTimeout(() => {
      setPDFReloading(false);
      formSubmissionQuery.refetch();
    }, PDF_LOADING_TIME);

    return () => {
      clearTimeout(pdfLoadingTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmission, formObject]);

  const showEditButton =
    !isEdit &&
    formSubmission?.data.status !== "accepted" &&
    formSubmission?.data?.status !== "rejected";

  const showPDFDownload =
    (!!formSubmission?.hasFile && !isEdit) || PDFReloading;

  return (
    <div className="flex  w-[100%] flex-col py-14">
      <button
        className={`mb-4 flex w-fit items-center gap-x-1 text-theme-black-secondary`}
        onClick={() => navigate("/quoting")}
        type="button"
      >
        <ArrowLeftIcon className="h-6 w-6" />
        <span className="text-xs font-medium">All Quotes</span>
      </button>

      {(!formObject || !formSubmission) && <LoadingSpinner />}

      {formObject && formSubmission && (
        <div className="flex justify-center">
          <section className="max-w-[800px] basis-3/4 rounded-lg">
            <div className="rounded-lg border bg-white">
              {isEdit && (
                <StickyHeader id="workflow-form-header">
                  <div className="flex w-full px-14">
                    <div className="flex w-full justify-center">
                      <div className="flex max-w-[800px] basis-3/4 content-center justify-between">
                        <h1 className="my-auto text-3xl font-semibold">
                          Quote Detail
                        </h1>
                        <div className="my-auto space-x-4 ">
                          <Button
                            label="Cancel"
                            variant="secondary"
                            className="rounded-md"
                            onClick={() => setIsEdit(false)}
                          />
                          <Button
                            onClick={onSave}
                            label="Save Changes"
                            variant="primary"
                            className="rounded-md text-white"
                            disabled={!isValidFormSubmit}
                            loading={editFormSubmissionLoading}
                          />
                        </div>
                      </div>
                      <div className="flex w-full max-w-[347px] basis-1/3" />
                    </div>
                  </div>
                </StickyHeader>
              )}

              <header className="flex justify-between rounded-t-lg bg-theme-white-secondary p-6">
                <div>
                  {formSubmission.data.number && (
                    <h3 className="text-base text-theme-black-secondary	">
                      {formSubmission.data.number}
                    </h3>
                  )}
                  <h1 className="text-4xl font-semibold">Quote Detail</h1>
                </div>

                {showEditButton && (
                  <div className="self-end">
                    <Button
                      className="rounded-md"
                      onClick={() => {
                        setIsEdit(true);
                        amplitude.track(AMPLITUDE_EVENTS.FORM_EDIT_ENTERED, {
                          form_number: formSubmission.data.number,
                          form_name: formObject.name,
                          status: formSubmission.data.status,
                        });
                      }}
                      loading={
                        editFormSubmissionLoading &&
                        !editFormSubmissionVariables?.body?.data
                      }
                      disabled={
                        editFormSubmissionLoading &&
                        !editFormSubmissionVariables?.body?.data
                      }
                      label="Edit"
                      variant="primary"
                      labelClasses="text-white"
                    />
                  </div>
                )}

                {isEdit && (
                  <div className="space-x-4 self-end">
                    <Button
                      label="Cancel"
                      variant="secondary"
                      className="rounded-md"
                      onClick={() => setIsEdit(false)}
                    />
                    <Button
                      onClick={onSave}
                      label="Save Changes"
                      variant="primary"
                      className="rounded-md text-white"
                      disabled={!isValidFormSubmit || editFormSubmissionLoading}
                      loading={editFormSubmissionLoading}
                    />
                  </div>
                )}
              </header>

              <Divider additionalClasses="border-gray-300" />

              {isEdit ? (
                <EditFormRenderer
                  submitRef={submitRef}
                  setIsValidFormSubmit={setIsValidFormSubmit}
                  handleSubmit={handleEditFormSubmissionFields}
                />
              ) : (
                <FormRenderer />
              )}
            </div>
          </section>

          <aside className="relative max-w-[347px] basis-1/3 space-y-12 px-8">
            {!isEdit && (
              <div>
                <h2 className="mb-4 text-2xl font-medium">Status</h2>
                <StatusSelector
                  type="quote"
                  status={formSubmission.data?.status ?? "draft"}
                  loading={
                    editFormSubmissionLoading &&
                    !!editFormSubmissionVariables?.body?.data?.status
                  }
                  onChange={(item) => {
                    handleEditFormSubmissionStatus(
                      item.value.toLocaleLowerCase()
                    );
                  }}
                />
              </div>
            )}
            {!isEdit && (
              <div>
                <h2 className="mb-4 text-2xl font-medium">Actions</h2>
                <div className="space-y-5">
                  <button
                    className=" flex justify-center rounded-full  border border-gray-400 px-6 py-2"
                    onClick={duplicateQuote}
                  >
                    <i className="mr-1">
                      <PagesIcon />
                    </i>
                    Duplicate Quote
                  </button>
                  <button
                    onClick={convertToJob}
                    className=" flex justify-center rounded-full  border border-gray-400 px-6 py-2"
                  >
                    <i className="mr-2">
                      <ConvertIcon />
                    </i>
                    Convert To Job
                  </button>
                </div>
              </div>
            )}

            {showPDFDownload && (
              <div>
                <h2 className="mb-4 text-2xl font-medium">Documents</h2>
                <div>
                  <button
                    className="flex h-6 content-center space-x-3"
                    onClick={() => {
                      if (PDFReloading) return;
                      if (exportDocumentPDF.isPending) return;
                      handleDocumentPDF(formSubmission.id);
                    }}
                  >
                    {exportDocumentPDF.isPending ? (
                      <Spinner />
                    ) : (
                      <PDFIcon disabled={PDFReloading} />
                    )}
                    {PDFReloading ? (
                      <span className="text-sm ">PDF Loading...</span>
                    ) : (
                      <span className="text-sm underline">
                        Download{" "}
                        {formObject?.layouts?.web?.downloadName ??
                          formObject.name}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            )}

            <EditHistory
              auditData={auditData}
              editHistoryLoading={isEditFormSubmissionHistoryLoading}
            />
          </aside>
        </div>
      )}
    </div>
  );
};

export default QuoteDetailsPage;
